<template>
  <div class="model-photo-section">
    <div class="model-photo-section-input">
      <label>
        <span bold>Fecha de sesión</span>
        <input type="datetime-local" name="photoSesionDate" :value="$global.toInputDateTime(model.photoSesionDate)" />
      </label>
    </div>
    <div class="model-photo-section-input">
      <label>
        <span>Fotógrafo</span>
        <select name="photoSesionPhotographer">
          <option value="">Seleccione un fotógrafo</option>
          <option
            v-for="(photographer, index) in photographers"
            :key="index"
            :value="photographer.documentID"
            :selected="model.photoSesionPhotographer === photographer.documentID"
          >
            {{ photographer.name }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["model"],
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    getPhotographers: async function () {
      if (!this.$store.state.photographers) {
        this.loading = true;
        this.$store.state.photographers = "loading";
        try {
          const host = `${process.env.VUE_APP_API_HOST}employees/photographers`;
          const { data } = await this.$api.get(host);
          this.filterPhotographers(data);
        } catch (error) {
          this.$swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      }
    },
    filterPhotographers: function (photographers) {
      const NewPhotographers = {};
      photographers?.map((photographer) => {
        NewPhotographers[this.$global.normalizeString(photographer.city)] =
          typeof NewPhotographers[this.$global.normalizeString(photographer.city)] != "undefined" &&
          NewPhotographers[this.$global.normalizeString(photographer.city)] instanceof Array
            ? NewPhotographers[this.$global.normalizeString(photographer.city)]
            : [];

        NewPhotographers[this.$global.normalizeString(photographer.city)].push(photographer);
      });

      this.$store.state.photographers = NewPhotographers;
      this.$store.state.photographers.all = photographers;
    },
  },
  beforeMount: function () {
    this.getPhotographers();
  },
  computed: {
    photographers: function () {
      return (
        (this.$store.state.photographers && this.$store.state.photographers[this.$global.normalizeString(this.model.city)]) ||
        (this.$store.state.photographers && this.$store.state.photographers["all"])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.model-photo-section {
  margin: $mpadding/2 0 0 0;
  &-input:not(:last-child) {
    margin: 0 0 $mpadding/2 0;
  }
}
</style>
